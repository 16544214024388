$w-ar : 80vw;
$h-ar : 80vh;

body {
	background-color: rgb(6, 10, 48);
}
$mq-breakpoints: (
	mobile: 425px,
	btwMobileland: 510px,
	tablet: 590px,
	middleDesk: 800px,
	desktop: 900px,
	largescreen: 1300px
);

@import "mq";


video {
	z-index: 0 !important;
}

.mainTemplate, .a-scene {
  height: 100%;
}

.a-canvas {
	z-index: 1 !important;
	height: 100%;
	width: 100%;
	left: 0;
	top: 0;
	position: absolute;
}




.loader {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100px;
	height: 100px;
	margin: auto;
	z-index: 4;
}



// #camera_corners {
//     position: absolute;
//     width: 50%;
//     height: 50%;
//     top: 25%;
//     left: 20%;

// }

// #camera_corners:before,
// #camera_corners:after,
// #camera_corners>:first-child:before,
// #camera_corners>:first-child:after {
//     position: absolute;
//     width: 10%;
//     height: 10%;
//     border-color: white;
//     /* or whatever colour */
//     border-style: solid;
//     /* or whatever style */
//     content: ' ';
//     z-index: 10;
// }

// #camera_corners:before {
//     top: 0;
//     left: 0;
//     border-width: 1px 0 0 1px;

// }

// #camera_corners:after {
//     top: 0;
//     right: 0;
//     border-width: 1px 1px 0 0;

// }

// #camera_corners>:first-child:before {
//     bottom: 0;
//     right: 0;
//     border-width: 0 1px 1px 0;

// }

// #camera_corners>:first-child:after {
//     bottom: 0;
//     left: 0;
//     border-width: 0 0 1px 1px;

// }

#cursor {
	height: 100%;
	width: 100%;
}


#main-scene {
	width: 100%!important;
	height: 100%!important;
	z-index: 2!important;
	overflow-x: hidden!important;
}


html, body{
	overflow: hidden;
}

* body{
	overflow-x: hidden!important;
}
