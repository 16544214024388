#landingPage {
  z-index: 5 !important;
  background: url("/assets/landingPage/sfondodesk-01.png") no-repeat center center fixed;
  -webkit-background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  background-size: 100% 100%;
  overflow: hidden;
	
  /* Set up proportionate scaling */
  width: 100%;
  height: 100%;
	
  /* Set up positioning */
  position: fixed;
  top: 0;
  left: 0;
}

#bottom-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: rgb(209, 210, 212);
  width: 100%;
  height: 7.4vh;
}
#background {
  width: 100%;
  height: 100%;
  display: none;
}
#logo {
  position: absolute;
  top: 0;
  left: 10px;
  width: 400px;
  height: auto;
}

#arms {
  position: absolute;
  top: 10vh;
  left: 24vw;
  width: 500px;
  height: auto;
}

.text {
  font-family: 'Roboto Mono';
  color: white;


}

#text1 {
  position: absolute;
  top: 30vh;
  left: 19vw;
  width: 100%;;
  height: auto;
  text-align: left;
  div {
    font-size: calc(1rem + 2vw);
    width: 75%;
    margin: auto;
  } 
}

#text2 {
  position: absolute;
  bottom: 24vh;
  right: 0;
  width: 100%;
  height: auto;
  text-align: center;

  div {
    font-size: calc(1rem + 0.1vw);
    width: 95%;
    margin: auto;
  }
}


#device-marker {
  position: absolute;
  top: 8vh;
  right: 13vw;
  width: 450px;
  height: auto;
}

html, body {
  height: 100%;
}

$mq-breakpoints: (
  smallscreen: 350px,
	mobile: 428px,
	btwMobileland: 510px,
  tablet: 590px,
  switchBackground: 800px,
	middleDesk: 800px,
  desktop: 900px,
  largescreen: 1100px,
  largescreen1: 1300px,
  largescreen2: 1500px,
  largescreen3: 1700px,
  largescreen4: 1900px,
  largescreen5: 2100px,
  largescreen6: 2300px,
  largescreen7: 2500px,


);

@import "mq";

@include mq($until: switchBackground){
	#landingPage {
    background: url("/assets/landingPage/sfondo-mobile-07.svg") no-repeat 50% 50% fixed;
  }


  #arms {
    top: 35vh;
    left: 19vw;
    width: 232px !important;
    height: auto;
  }

  #logo {
    top: 4vh;
    left: 10vw;
  }
  
  #text1 {
    top: 14vh;
    height: auto;
    margin: auto;
  }

  #device-marker {
    top: 35vh;
    right: 5vw;
    width: 225px;
  }


  #div-img {
    bottom: 15vh;
  }
}




@include mq($until: smallscreen){
  #text1 {
    div {
      font-size: calc(1rem + 0.9vw);
      width: 75%;
    }
  }
}
@include mq($until: mobile){

  #arms {
    top: 34vh;
    left: 11vw;
    width: 47vw !important;
    height: auto;
  }

  #logo {
    top: 2vh;
    left: 1vw;
    width: 62vw;
  }
  
  #text1 {
    top: 16vh;
    left: 0;
    height: auto;
    margin: auto;
  }

  #device-marker {
    top: 31vh;
    right: 12vw;
    width: 35vw;
  }

}

@include mq($from: mobile, $until: tablet){
  #arms {
    top: 25vh;
    left: 11vw;
    width: 47vw !important;
    height: auto;
  }

  #logo {
    top: 4vh;
    left: 5vw;
    width: 225px;
  }
  
  #text1 {
    top: 14vh;
    left: 0;
    height: auto;
    margin: auto;

    div {
      font-size: calc(1rem + 1.3vw);
    }
  }

  #device-marker {
    top: 22vh;
    right: 14vw;
    width: 35vw;
  }

  #text2 {
    bottom: 21vh;
    
    div {
      font-size: calc(1rem + 0.6vw);
    }
  }

}

@include mq($from: tablet, $until: middleDesk){
  #arms {
    top: 25vh;
    left: 15vw;
    width: 37vw !important;
    height: auto;
  }

  #logo {
    top: 1vh;
    left: 4vw;
    width: 270px;
  }

  #text1 {
    top: 18vh;
    left: 0;
    height: auto;
    margin: auto;

    div {
      font-size: calc(1rem + 1.5vw);
    }
  }

  #device-marker {
    top: 24vh;
    right: 20vw;
    width: 31vw;
  }

  #text2 {
    bottom: 19vh;
    
    div {
      font-size: calc(1rem + 1.3vw);
    }
  }

}


@include mq($from: middleDesk, $until: desktop){
  #arms {
    top: 25vh;
    left: 23vw;
    width: 32vw !important;
    height: auto;
  }

  #logo {
    top: 2vh;
    left: 6vw;
    width: 290px;
  }

  #text1 {
    top: 18vh;
    left: 0;
    height: auto;
    margin: auto;

    div {
      font-size: calc(1rem + 1.1vw);
    }
  }

  #device-marker {
    top: 22vh;
    right: 20vw;
    width: 27vw;
  }

  #text2 {
    bottom: 26vh;
    
    div {
      font-size: calc(1rem + 0.7vw);
    }
  }

}

@include mq($from: desktop, $until: largescreen){
  #arms {
    top: 21vh;
    left: 26vw;
    width: 36vw !important;
    height: auto;
  }

  #logo {
    top: 4vh;
    left: 0;
    width: 400px;
  }

  #text1 {
    top: 37vh;
    left: 19vw;
    height: auto;
    margin: auto;
    width: 26%;
    div {
      font-size: calc(1rem + 0.2vw);
    }
  }

  #device-marker {
    top: 11vh;
    right: 16vw;
    width: 27vw;
  }

  #text2 {
    bottom: 32vh;
    right: 0vw;
    width: 45%;

    div {
      font-size: calc(1rem + 0.6vw);
    }
  }

}

@include mq($from: largescreen, $until: largescreen1){
  #arms {
    top: 16vh;
    left: 28vw;
    width: 33vw !important;
    height: auto;
  }

  #logo {
    top: 4vh;
    left: 0;
    width: 400px;
  }

  #text1 {
    top: 32vh;
    left: 22vw;
    height: auto;
    margin: auto;
    width: 27%;
    div {
      font-size: calc(1rem + 0.2vw);
    }
  }

  #device-marker {
    top: 9vh;
    right: 19vw;
    width: 24vw;
  }

  #text2 {
    bottom: 32vh;
    right: 0vw;
    width: 45%;

    div {
      font-size: calc(1rem + 0.6vw);
    }
  }

}

@include mq($from: largescreen1, $until: largescreen2){
  #arms {
    top: 14vh;
    left: 27vw;
    width: 32vw !important;
    height: auto;
  }

  #logo {
    top: 4vh;
    left: 0;
    width: 400px;
  }

  #text1 {
    top: 29vh;
    left: 19vw;
    height: auto;
    margin: auto;
    width: 26%;
    div {
      font-size: calc(1rem + 0.8vw);
    }
  }

  #device-marker {
    top: 5vh;
    right: 18vw;
    width: 25vw;
  }

  #text2 {
    bottom: 32vh;
    right: 0vw;
    width: 45%;

    div {
      font-size: calc(1rem + 0.9vw);
    }
  }

}

@include mq($from: largescreen2, $until: largescreen3){
  #arms {
    top: 14vh;
    left: 27vw;
    width: 32vw !important;
    height: auto;
  }

  #logo {
    top: 4vh;
    left: 0;
    width: 400px;
  }

  #text1 {
    top: 29vh;
    left: 19vw;
    height: auto;
    margin: auto;
    width: 26%;
    div {
      font-size: calc(1rem + 0.8vw);
    }
  }

  #device-marker {
    top: 5vh;
    right: 18vw;
    width: 25vw;
  }

  #text2 {
    bottom: 32vh;
    right: 0vw;
    width: 45%;

    div {
      font-size: calc(1rem + 0.9vw);
    }
  }

}


@include mq($from: largescreen3, $until: largescreen4){
  #arms {
    top: 15vh;
    left: 16vw;
    width: 47vw !important;
    height: auto;
  }

  #logo {
    top: 4vh;
    left: 0;
    width: 500px;
  }

  #text1 {
    top: 33vh;
    left: 13vw;
    height: auto;
    margin: auto;
    width: 32%;
    div {
      font-size: calc(1rem + 0.8vw);
    }
  }

  #device-marker {
    top: 8vh;
    right: 7vw;
    width: 35vw;
  }

  #text2 {
    bottom: 32vh;
    right: 2vw;
    width: 45%;

    div {
      font-size: calc(1rem + 0.9vw);
    }
  }

}

@include mq($from: largescreen4, $until: largescreen5){
  #arms {
    top: 8vh;
    left: 16vw;
    width: 47vw !important;
    height: auto;
  }

  #logo {
    top: 4vh;
    left: 0;
    width: 500px;
  }

  #text1 {
    top: 30vh;
    left: 15vw;
    height: auto;
    margin: auto;
    width: 500px;
    div {
      font-size: calc(1rem + 0.8vw);
    }
  }

  #device-marker {
    top: 1vh;
    right: 7vw;
    width: 35vw;
  }

  #text2 {
    bottom: 32vh;
    right: 2vw;
    width: 45%;

    div {
      font-size: calc(1rem + 0.9vw);
    }
  }

}


@include mq($from: largescreen5, $until: largescreen6){
  #arms {
    top: 8vh;
    left: 16vw;
    width: 47vw !important;
    height: auto;
  }

  #logo {
    top: 4vh;
    left: 0;
    width: 500px;
  }

  #text1 {
    top: 30vh;
    left: 15vw;
    height: auto;
    margin: auto;
    width: 500px;
    div {
      font-size: calc(1rem + 0.8vw);
    }
  }

  #device-marker {
    top: 1vh;
    right: 7vw;
    width: 35vw;
  }

  #text2 {
    bottom: 32vh;
    right: 2vw;
    width: 45%;

    div {
      font-size: calc(1rem + 0.9vw);
    }
  }

}


@include mq($from: largescreen6, $until: largescreen7){
  #arms {
    top: 12vh;
    left: 22vw;
    width: 37vw !important;
    height: auto;
  }

  #logo {
    top: 4vh;
    left: 0;
    width: 650px;
  }

  #text1 {
    top: 30vh;
    left: 20vw;
    height: auto;
    margin: auto;
    width: 500px;
    div {
      font-size: calc(1rem + 0.8vw);
    }
  }

  #device-marker {
    top: 5vh;
    right: 15vw;
    width: 28vw;
  }

  #text2 {
    bottom: 32vh;
    right: 2vw;
    width: 45%;

    div {
      font-size: calc(1rem + 0.9vw);
    }
  }

}

@include mq($from: largescreen7){
  #arms {
    top: 12vh;
    left: 22vw;
    width: 37vw !important;
    height: auto;
  }

  #logo {
    top: 4vh;
    left: 0;
    width: 650px;
  }

  #text1 {
    top: 30vh;
    left: 20vw;
    height: auto;
    margin: auto;
    width: 500px;
    div {
      font-size: calc(1rem + 0.8vw);
    }
  }

  #device-marker {
    top: 5vh;
    right: 15vw;
    width: 28vw;
  }

  #text2 {
    bottom: 32vh;
    right: 2vw;
    width: 45%;

    div {
      font-size: calc(1rem + 0.9vw);
    }
  }

}